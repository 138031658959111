import { useCallback, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

import { useGetMemberDetail } from '@api';
import { useOverlayContext } from '@context/OverlayContext';
import { MEMBER_TYPE_CD } from '@constants/consultation';
import useConsultationStore from '@store/useConsultationStore';
import { ModalMemberSelector } from '@components/overlay';
import {
  useGetMemberConsultation,
  useGetNonMemberConsultation,
} from '@api/consultation';

function useConsultationDetail({ page = 1 } = {}) {
  const { openModal } = useOverlayContext();
  const [currentMember, setCurrentMember] = useConsultationStore((state) => [
    state.currentMember,
    state.setCurrentMember,
  ]);

  const [payload, setPayload] = useState({
    page: 1,
  });

  const isMember = Boolean(currentMember?.memberId);

  const { data: memberDetail } = useGetMemberDetail(payload);
  const { data: memberData, isLoading: memberLoading } =
    useGetMemberConsultation(
      {
        page,
        customerId: currentMember?.memberId,
      },
      {
        enabled: Boolean(currentMember?.memberId),
      },
    );
  const { data: nonMemberData, isLoading: nonMemberLoading } =
    useGetNonMemberConsultation(
      {
        page,
        customerPhoneNumber: currentMember?.mobile,
      },
      {
        enabled: Boolean(currentMember?.mobile),
      },
    );

  const handleGetMemberDetail = useCallback(
    ({ memberId, memberType, memberTypeNm }) => {
      const type = Object.keys(MEMBER_TYPE_CD)
        .find((key) => MEMBER_TYPE_CD[key] === memberType)
        .toLowerCase();

      setPayload({ memberId, type, memberTypeNm });
    },
    [],
  );

  const handleCheckMembers = useCallback(
    (result) => {
      const total = result?.list?.length || result?.total;
      // 조회된 회원이 여려명인 경우
      if (total > 1) {
        openModal(ModalMemberSelector, {
          members: result,
          onSuccess: (member) => handleGetMemberDetail(member),
        });
      }

      // 1명인 경우
      if (total === 1) {
        const [member] = result.list;
        handleGetMemberDetail({
          memberId: member.memberId,
          memberType: member.memberTypes?.[0].memberTypeCd,
        });
      }
    },
    [handleGetMemberDetail, openModal],
  );

  useEffect(() => {
    if (!isEmpty(memberDetail)) {
      setCurrentMember({
        ...memberDetail,
        memberTypeNm: payload?.memberTypeNm,
      });
    }
  }, [memberDetail, payload, setCurrentMember]);

  return {
    setCurrentMember,
    handleCheckMembers,
    handleGetMemberDetail,
    consultation: isMember ? memberData : nonMemberData,
    isLoading: isMember ? memberLoading : nonMemberLoading,
    totalPages: isMember ? memberData?.totalPages : nonMemberData?.totalPages,
  };
}

export default useConsultationDetail;
