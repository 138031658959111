import { DatePicker, Form } from 'antd';
import dayjs from 'dayjs';

function FormRangeDatePicker({
  label,
  name,
  required = false,
  defaultRange = 10,
  className = '',
  onChange,
}) {
  const now = dayjs();
  const defaultAgo = now.subtract(defaultRange, 'day');
  const weekAgo = now.subtract(7, 'day');
  const format = 'YYYY-MM-DD';

  const presetArr = [
    { label: '오늘', value: [dayjs(now, format), dayjs(now, format)] },
    { label: '일주일', value: [dayjs(weekAgo, format), dayjs(now, format)] },
    {
      label: `${defaultRange}일`,
      value: [dayjs(defaultAgo, format), dayjs(now, format)],
    },
  ];

  return (
    <Form.Item
      label={label || '날짜 선택'}
      name={name}
      colon={false}
      initialValue={[dayjs(defaultAgo, format), dayjs(now, format)]}
      rules={[{ required, message: '필수 입력 입니다.' }]}
      className={className}
    >
      <DatePicker.RangePicker
        {...(onChange && { onChange })}
        allowClear
        picker="date"
        format={format}
        presets={presetArr}
      />
    </Form.Item>
  );
}

export default FormRangeDatePicker;
