import { useCallback, useState } from 'react';
import { useGetConsultation } from '@api/consultation';

export const DEFAULT_PAGE_SIZE = 10;

function useConsultation() {
  const [payload, setPayload] = useState({
    page: 1,
    size: DEFAULT_PAGE_SIZE,
    customerId: undefined,
    customerName: undefined,
    customerPhone: undefined,
    startDate: '',
    endDate: '',
  });

  const { data: consultation, refetch: refetchConsultation } =
    useGetConsultation(payload);

  const handleSetPage = useCallback(
    (page) => {
      setPayload({ ...payload, page });
    },
    [payload],
  );

  const handleSetPayload = useCallback(
    (value) => {
      setPayload({ ...payload, ...value });
    },
    [payload],
  );

  const handleSearch = useCallback(() => {
    refetchConsultation();
  }, [refetchConsultation]);

  return {
    consultation,
    handleSetPage,
    handleSetPayload,
    handleSearch,
  };
}

export default useConsultation;
