import { useCallback } from 'react';
import { create } from 'zustand';
import { useGetAgents } from '@api/account';
import { persist } from 'zustand/middleware';

const initialValue = {
  agentNumber: '',
  agentId: null,
};

const useAccountStore = create((set) => ({
  ...initialValue,
  clear: () => set(initialValue),
  setAgent: (props) => set((state) => ({ ...state, ...props })),
  setUserRoles: (props) => set((state) => ({ ...state, userRoles: props })),
}));

export const useUserRolesStore = create(
  persist(
    (set) => ({
      userRoles: [],
      setUserRoles: (roles) => set({ userRoles: roles }),
      clearUserRoles: () => set({ userRoles: [] }),
    }),
    {
      name: 'user-roles',
    },
  ),
);

export const useAccountActions = () => {
  const setAgent = useAccountStore((state) => state.setAgent);
  const { refetch: refetchAccount } = useGetAgents();

  const fetchAccount = useCallback(() => {
    (async () => {
      const { data } = await refetchAccount();

      setAgent({
        agentNumber: data?.number,
        agentId: data?.agentId,
      });
    })();
  }, [refetchAccount, setAgent]);

  return {
    fetchAccount,
  };
};

export default useAccountStore;
