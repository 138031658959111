import { useEffect } from 'react';
import { Button, Cascader, Col, Form, Input, Radio, Row } from 'antd';
import { isEmpty } from 'lodash';

import { usePostConsultation } from '@api/consultation';
import { toPhoneNumberFormat } from '@helper/formatHelper';
import useCodeStore, { CODE_KEY } from '@store/useCodeStore';
import useConsultationStore from '@store/useConsultationStore';
import { useCallSocketStore } from '@feature/cti/store/useCallSocketStore';
import { shallow } from 'zustand/shallow';
import { CALL_TYPE } from '@constants/consultation';
import { useCallerContext } from '@context/CallerContext';

function ConsultationForm() {
  const [form] = Form.useForm();

  const reset = () => {
    form.resetFields(['category', 'content']);
  };

  const [call] = useCallSocketStore((state) => [state.call], shallow);
  const { currentMember: member } = useConsultationStore((state) => state);
  const requestConsultation = usePostConsultation(() => {
    reset();
  });
  const { isOnCall } = useCallerContext();

  const { [CODE_KEY.CONSULTATION_CATEGORY_TREE]: consultCategoryTree } =
    useCodeStore((state) => state.code);

  const handleSubmit = (formValue) => {
    const { errors } = form.getFieldsError();
    const categoryValue = [...formValue.category];

    if (isEmpty(errors) && !isEmpty(formValue)) {
      requestConsultation(
        {
          ...formValue,
          category: categoryValue.pop(),
          customerId: `${member?.memberId}`,
          customerPhone: member?.mobile || '',
          fileKey: call.file,
          callType: Object.values(CALL_TYPE).find(
            (type) => type.EVENT === call.eventType,
          )?.VALUE,
        },
        {
          onSuccess: () => {
            reset();
          },
        },
      );
    }
  };

  useEffect(() => {
    form.setFieldsValue({ customerName: member?.memberName || '' });
    form.setFieldsValue({ content: '' });
  }, [form, member?.memberName]);

  return (
    <Form form={form} onFinish={handleSubmit}>
      <Row gutter={[40, 0]}>
        <Col span={12}>
          <Form.Item label="이름" name="customerName" colon={false}>
            <Input maxLength={20} disabled={!isEmpty(member?.memberName)} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="연락처" colon={false}>
            {toPhoneNumberFormat(member?.mobile || '')}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Form.Item
          colon={false}
          name="category"
          label="카테고리"
          initialValue=""
          rules={[{ required: true, message: '필수 입력 입니다.' }]}
        >
          <Cascader
            options={consultCategoryTree}
            placeholder="카테고리 선택"
            allowClear
          />
        </Form.Item>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            label="메모"
            name="content"
            rules={[{ required: true, message: '필수 입력 입니다.' }]}
            colon={false}
          >
            <Input.TextArea rows="8" />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="end">
        <Button htmlType="submit" type="primary" disabled={isOnCall}>
          저장
        </Button>
      </Row>
    </Form>
  );
}

export default ConsultationForm;
