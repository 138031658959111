const onCopyToClipboard = (copyString) => {
  navigator.clipboard.writeText(copyString);
};

const getAgentStatus = (e) => {
  if (e.status === '1') {
    return ['로그인', 'magenta'];
  }
  if (e.status === '0') {
    return ['로그아웃', 'red'];
  }
  if (e.status === '2' && e.paused === '0' && e.reason === '0') {
    return ['통화대기', 'green'];
  }
  if (e.status === '2' && e.paused === '1' && e.reason === '10') {
    return ['문서', 'cyan'];
  }
  if (e.status === '2' && e.paused === '1' && e.reason === '11') {
    return ['이석', 'orange'];
  }
  if (e.status === '2' && e.paused === '1' && e.reason === '13') {
    return ['수신거절'];
  }
  if (e.device === '2' || e.device === '3') {
    return ['통화중', 'blue'];
  }
  if (e.device === '0' || e.device === '4' || e.device === '5') {
    return ['서버와 연결 끊김', '#888888'];
  }
  return ['', '#888888'];
};

export { onCopyToClipboard, getAgentStatus };
