import { Navigate, useLocation } from 'react-router-dom';
import storage from '@utils/Storage';

const withPrivate = (Component) => {
  function WithPrivateComponent(props) {
    const location = useLocation();

    if (!storage.hasSession()) {
      window.alert('미인증 상태입니다. 로그인 페이지로 이동합니다.');

      return <Navigate to="/login" replace state={{ from: location }} />;
    }

    return <Component {...props} />;
  }

  WithPrivateComponent.displayName = `WithPrivate(${Component.displayName || Component.name || 'Component'})`;

  return WithPrivateComponent;
};

export default withPrivate;
