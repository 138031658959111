import { useCallback, useState } from 'react';
import { Modal, Button, Table } from 'antd';
import { useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { useOverlayContext } from '@context/OverlayContext';

import ModalContainer from './base/ModalContainer';

function ModalMemberSelector({ members, onSuccess }) {
  const [member, setMember] = useState();
  const { closeModal } = useOverlayContext();

  const location = useLocation();
  const searchValue = location.pathname.split('/').pop();

  const handleSelect = useCallback(() => {
    onSuccess?.(member);
    closeModal();
  }, [closeModal, member, onSuccess]);

  return (
    <ModalContainer>
      <Modal
        open
        title="회원 선택"
        closable={false}
        footer={
          <Button
            disabled={isEmpty(member)}
            type="primary"
            onClick={() => handleSelect()}
          >
            선택
          </Button>
        }
        width="60%"
      >
        {members.total > 0 && (
          <>
            <h5>
              <span className="text-secondary">{searchValue}</span>
              으로 조회된 회원이 여러명 존재합니다
            </h5>
            <Table
              total={members.total}
              dataSource={members?.list || []}
              rowSelection={{
                type: 'radio',
                selectedRowKeys: [member?.key],
                onChange: (_, selectedRows) => {
                  if (selectedRows) {
                    setMember(selectedRows[0]);
                  }
                },
              }}
              onRow={(record) => ({ onClick: () => setMember(record) })}
              columns={[
                { title: '회원번호', dataIndex: 'memberId' },
                { title: '회원명', dataIndex: 'memberName' },
                { title: '회원유형', dataIndex: 'memberTypeNm' },
                { title: '회원등급', dataIndex: 'memberGradeNm' },
                { title: '회원상태', dataIndex: 'memberStateNm' },
                {
                  title: '유입경로',
                  width: 250,
                  render: (row) =>
                    `${row.inboundChannelGroupNm} / ${row.inboundChannelNm} `,
                },
              ]}
              scroll={{ y: 400 }}
              pagination={false}
            />
          </>
        )}
      </Modal>
    </ModalContainer>
  );
}

export default ModalMemberSelector;
