import { api, casApi } from '@utils/Api';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useOverlayContext } from '@context/OverlayContext';
import { DEFAULT_PAGE_SIZE } from '@hooks/consultation/useConsultation';
import { useCallSocketStore } from '@feature/cti/store/useCallSocketStore';
import { shallow } from 'zustand/shallow';

/**
 * 상담내역 조회 > 메인페이지 상담내역 조회
 */
export const useGetConsultation = (params) =>
  useQuery(
    ['/external/v1/customer-consults', params],
    () => api.get('/external/v1/customer-consults', { params }),
    {
      enabled:
        !!params.customerPhone ||
        !!params.customerId ||
        (!!params.startDate && !!params.endDate),
    },
  );

/**
 * 상담내역 조회 > 회원 상담내역 조회(회원)
 */
export const useGetMemberConsultation = (params) =>
  useQuery(
    ['/external/v1/customer-consults/customer', params],
    () =>
      api.get('/external/v1/customer-consults/customer', {
        params: {
          ...params,
          size: DEFAULT_PAGE_SIZE,
        },
      }),
    {
      enabled: !!params.customerId,
    },
  );

/**
 * 상담내역 조회 > 비회원 상담내역 조회(비회원)
 */
export const useGetNonMemberConsultation = (params) =>
  useQuery(
    ['/external/v1/customer-consults/phone-numbers', params],
    () =>
      api.get('/external/v1/customer-consults/phone-numbers', {
        params: {
          ...params,
          size: DEFAULT_PAGE_SIZE,
        },
      }),
    {
      enabled: !!params.customerPhoneNumber,
    },
  );

export const useGetSubscribeHistory = (params) =>
  useQuery(
    [`/v1/member/student/${params.memberId}/subscribe-list`],
    () => api.get(`/v1/member/student/${params.memberId}/subscribe-list`),
    {
      enabled: !!params.memberId,
    },
  );

export const useGetPaymentHistory = ({
  memberId,
  page,
  pageSize = DEFAULT_PAGE_SIZE,
}) =>
  useQuery(
    [`/v1/member/${memberId}/order-payments`, { page, pageSize }],
    () =>
      api.get(`/v1/member/${memberId}/order-payments`, {
        params: { page, pageSize },
      }),
    {
      enabled: !!memberId,
    },
  );

/**
 * 상담내역 등록 > 고객 상담
 */
export const usePostConsultation = (reset) => {
  const queryClient = useQueryClient();
  const { openAlert, openSuccess } = useOverlayContext();
  const [clearCall] = useCallSocketStore((state) => [state.clearCall]);

  const onSuccess = () => {
    queryClient.invalidateQueries('/external/v1/customer-consults');
    clearCall();
    openSuccess('저장 되었습니다.');
    reset?.();
  };

  const onError = () => {
    openAlert('저장이 실패했습니다.');
  };

  const mutation = useMutation(
    (consultation) =>
      api.post('/external/v1/customer-consults', {
        ...consultation,
      }),
    { onSuccess, onError },
  );

  const requestConsultation = (consultation) => {
    mutation.mutate(consultation);
  };

  return requestConsultation;
};
