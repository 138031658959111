import { Button, Col, Form, Row, Space, Table } from 'antd';
import { useState } from 'react';
import {
  DATE_FORMAT,
  toDateFormat,
  toPhoneNumberFormat,
  toTelNumberFormat,
} from '@helper/formatHelper';

import { useOverlayContext } from '@context/OverlayContext';
import { ModalConsultation } from '@components/overlay';
import { usePostConsultReservationsException } from '@api/reservation';
import useReservation from '@hooks/useReservation';

function ReservationPage() {
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const { openModal } = useOverlayContext();

  const { reservations, page, handleSetPage } = useReservation();
  const requestConsultReservationsException =
    usePostConsultReservationsException();

  const handleOpenConsultation = (consultation) => {
    openModal(ModalConsultation, { consultation });
  };

  const handleExceptionClick = () => {
    requestConsultReservationsException(selectedRowIds);
  };

  const columns = [
    {
      title: '구분',
      dataIndex: 'id',
    },
    {
      title: '대표번호',
      dataIndex: 'tel',
      render: toTelNumberFormat,
    },
    {
      title: '수신 날짜',
      dataIndex: 'incomingCalledAt',
      render: (incomingCalledAt) =>
        toDateFormat(incomingCalledAt, DATE_FORMAT.DATETIME),
    },
    {
      title: '전화번호',
      dataIndex: 'customerContactNumber',
      render: toPhoneNumberFormat,
    },
    {
      title: '',
      dataIndex: '',
      render: (consultation) => (
        <Button onClick={() => handleOpenConsultation(consultation)}>
          전화걸기
        </Button>
      ),
    },
  ];

  const disabled = selectedRowIds.length === 0;

  return (
    <>
      <Row justify="space-between" align="bottom">
        <Col className="font-semibold">
          총&nbsp;
          <span className="text-secondary">{reservations?.total}</span>개
        </Col>
      </Row>
      <Table
        className="pt-3"
        rowKey="id"
        columns={columns}
        dataSource={reservations?.list ?? []}
        pagination={{
          showSizeChanger: false,
          total: reservations?.total || 0,
          current: page,
          onChange: (current) => handleSetPage(current),
        }}
        rowSelection={{
          selectedRowKeys: selectedRowIds,
          onChange: setSelectedRowIds,
        }}
      />
      <Col className="mt-3 flex justify-end">
        <Space size="small" className="mr-1">
          <Button
            type={disabled ? 'default' : 'primary'}
            disabled={disabled}
            onClick={handleExceptionClick}
          >
            예외 완료처리
          </Button>
        </Space>
      </Col>
    </>
  );
}

export default ReservationPage;
