import { useGetConsultReservations } from '@api/reservation';
import { useCallback, useState } from 'react';

export default function useReservation() {
  const [payload, setPayload] = useState({
    page: 1,
    pageSize: 10,
  });

  const { data: reservations } = useGetConsultReservations(payload);

  const handleSetPage = useCallback(
    (page) => {
      setPayload({ ...payload, page });
    },
    [payload],
  );

  return {
    reservations,
    page: payload.page,
    handleSetPage,
  };
}
