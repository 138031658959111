import { useCallback, useMemo } from 'react';
import { useMount } from 'ahooks';
import { Button, Col, Form, Row, Table } from 'antd';

import { FormRangeDatePicker, FormSearch, FormSelect } from '@components/form';
import { Search } from '@components/ui';
import {
  DATE_FORMAT,
  toDateFormat,
  toPhoneNumberFormat,
} from '@helper/formatHelper';
import { useOverlayContext } from '@context/OverlayContext';
import { ModalConsultationSchedule } from '@components/overlay';
import useSchedule from '@hooks/schedule/useSchedule';

function SchedulePage() {
  const [form] = Form.useForm();

  const { openModal } = useOverlayContext();
  const { schedule, handleSetPage, handleSetPayload } = useSchedule();

  const goDetail = useCallback(
    (id) => {
      openModal(ModalConsultationSchedule, { id });
    },
    [openModal],
  );

  const handleSubmit = useCallback(
    (formValue) => {
      const {
        consultDate: [startDate, endDate],
      } = formValue;
      const payload = {
        completeYn: formValue.completeYn,
        searchKey: formValue.searchKey,
        searchValue: formValue.searchValue,
        startDate: toDateFormat(startDate, DATE_FORMAT.DATE),
        endDate: toDateFormat(endDate, DATE_FORMAT.DATE),
      };

      handleSetPayload({
        ...payload,
        page: 1,
      });
    },
    [handleSetPayload],
  );

  const columns = useMemo(() => {
    return [
      { title: '회원명', dataIndex: 'customerName' },
      {
        title: '전화번호',
        dataIndex: 'customerPhone',
        render: (customerPhone, record) => (
          <Button
            className="p-0"
            type="link"
            onClick={() => goDetail(record.id)}
          >
            {toPhoneNumberFormat(customerPhone)}
          </Button>
        ),
      },
      { title: '예약상담시간', dataIndex: 'scheduleDate' },
      {
        title: '상태',
        dataIndex: 'completeYn',
        render: (completeYn) => (completeYn === 'Y' ? '처리' : '미처리'),
      },
      { title: '처리자', dataIndex: 'completeAdminName' },
      {
        title: '처리일시',
        dataIndex: 'completeDate',
        render: (completeDate) => toDateFormat(completeDate),
      },
      { title: '등록자', dataIndex: 'regName' },
      {
        title: '등록일시',
        dataIndex: 'regDate',
        render: (createdDate) => toDateFormat(createdDate),
      },
    ];
  }, [goDetail]);

  useMount(() => {
    form.submit();
  });

  return (
    <Form form={form} onFinish={handleSubmit}>
      <Search>
        <Search.Item className="flex-auto">
          <FormSelect
            label="구분"
            name="completeYn"
            defaultValue="N"
            options={[
              { value: 'Y', label: '처리' },
              { value: 'N', label: '미처리' },
            ]}
          />
        </Search.Item>
        <Search.Item className="flex-auto">
          <FormSearch
            label="검색"
            name="search"
            defaultValue="customerName"
            options={[
              { value: 'customerName', label: '회원명' },
              { value: 'customerPhone', label: '연락처' },
              { value: 'regName', label: '등록자' },
            ]}
          />
        </Search.Item>

        <Search.Item className="flex-auto">
          <FormRangeDatePicker label="상담 날짜" name="consultDate" required />
        </Search.Item>
      </Search>
      <Row justify="space-between" align="bottom">
        <Col className="font-semibold">
          총&nbsp;
          <span className="text-secondary">{schedule?.total || 0}</span>개
        </Col>
      </Row>
      <Table
        className="pt-3"
        rowKey="id"
        columns={columns}
        dataSource={schedule?.list}
        pagination={{
          showSizeChanger: false,
          total: schedule?.total || 0,
          current: schedule?.pageNum || 1,
          onChange: (current) => handleSetPage(current),
        }}
      />
    </Form>
  );
}

export default SchedulePage;
