import withPrivate from '@hoc/withPrivate';
import workConfig from './private/workConfig';
import scheduleConfig from './private/scheduleConfig';
import statusConfig from './private/statusConfig';

const privateRouteConfig = [
  ...workConfig,
  // ...scheduleConfig,
  // ...statusConfig,
].map((item) => {
  const Component = withPrivate(item.element);

  return {
    ...item,
    element: <Component />,
  };
});

export default privateRouteConfig;
