import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import {
  Modal,
  Button,
  Descriptions,
  Input,
  TimePicker,
  DatePicker,
  Form,
} from 'antd';

import { useOverlayContext } from '@context/OverlayContext';
import {
  useGetScheduleDetail,
  usePatchSchedule,
  usePatchConsultReservationsComplete,
} from '@api/schedule';
import { DATE_FORMAT, toPhoneNumberFormat } from '@helper/formatHelper';

import ModalContainer from './base/ModalContainer';

function ModalConsultationSchedule({ id }) {
  const { closeModal } = useOverlayContext();

  const { data: detail } = useGetScheduleDetail({ id });

  const requestUpdateSchedule = usePatchSchedule();
  const requestConsultReservationsComplete =
    usePatchConsultReservationsComplete();

  const [form] = Form.useForm();

  const handleUpdateSubmit = (formValue) => {
    requestUpdateSchedule({
      id,
      scheduleDate: `${dayjs(formValue.date).format(DATE_FORMAT.DATE)} ${dayjs(
        formValue.time,
      ).format(DATE_FORMAT.TIME)}`,
      scheduleMemo: formValue.scheduleMemo || '',
    });
  };

  const handleConsultation = () => {
    requestConsultReservationsComplete({
      id,
      customerPhone: detail?.customerPhone,
    });
  };

  const handleClose = () => {
    closeModal();
  };

  return (
    <ModalContainer>
      <Modal
        open
        title="상담 일정"
        closable={false}
        footer={
          <>
            {detail?.completeYn === 'N' && (
              <>
                <Button type="primary" onClick={() => form.submit()}>
                  수정
                </Button>
                <Button onClick={() => handleConsultation()}>상담하기</Button>
              </>
            )}
            <Button onClick={() => handleClose()}>닫기</Button>
          </>
        }
        width="40%"
      >
        {!isEmpty(detail) && (
          <Form form={form} onFinish={handleUpdateSubmit}>
            <Descriptions bordered className="my-5">
              <Descriptions.Item
                label="이름"
                labelStyle={{ width: '20%' }}
                span={3}
              >
                <div className="w-fit">{detail?.customerName}</div>
              </Descriptions.Item>
              <Descriptions.Item
                label="전화번호"
                labelStyle={{ width: '20%' }}
                span={3}
              >
                {toPhoneNumberFormat(detail?.customerPhone)}
              </Descriptions.Item>
              <Descriptions.Item
                label="일자"
                labelStyle={{ width: '20%' }}
                span={3}
              >
                {detail?.completeYn === 'N' && (
                  <Form.Item
                    className="mb-0"
                    name="date"
                    initialValue={dayjs(detail?.scheduleDate, DATE_FORMAT)}
                  >
                    <DatePicker
                      className="w-full"
                      allowClear
                      picker="date"
                      format={DATE_FORMAT}
                    />
                  </Form.Item>
                )}
                {detail?.completeYn === 'Y' && (
                  <>{dayjs(detail?.scheduleDate).format(DATE_FORMAT)}</>
                )}
              </Descriptions.Item>
              <Descriptions.Item
                label="시간"
                labelStyle={{ width: '20%' }}
                span={3}
              >
                {detail?.completeYn === 'N' && (
                  <Form.Item
                    className="mb-0"
                    name="time"
                    initialValue={dayjs(
                      detail?.scheduleDate,
                      DATE_FORMAT.DATETIME,
                    )}
                  >
                    <TimePicker
                      showNow={false}
                      className="w-full"
                      format={DATE_FORMAT.TIME}
                    />
                  </Form.Item>
                )}
                {detail?.completeYn === 'Y' && (
                  <>{dayjs(detail?.scheduleDate).format(DATE_FORMAT.TIME)}</>
                )}
              </Descriptions.Item>

              <Descriptions.Item
                label="일정내용"
                labelStyle={{ width: '20%' }}
                span={3}
              >
                <Form.Item
                  name="scheduleMemo"
                  initialValue={detail?.scheduleMemo}
                >
                  <Input.TextArea
                    rows="8"
                    disabled={detail?.completeYn === 'Y'}
                  />
                </Form.Item>
              </Descriptions.Item>
            </Descriptions>
          </Form>
        )}
      </Modal>
    </ModalContainer>
  );
}

export default ModalConsultationSchedule;
