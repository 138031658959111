import dayjs from 'dayjs';
import { isEmpty, isNaN } from 'lodash';

import { isDevelopment, isLocal, isProduction } from '@utils/systemPhaseUtils';
import { CATS_QUEUE } from '../data/constants';

const now = () => dayjs().format('HH:mm:ss');

function makeCallRatio(queue) {
  const totalSucCnt = queue?.suc_call || 0;
  const totalCnt = (queue?.fail_call || 0) + totalSucCnt;

  return isNaN(totalSucCnt / totalCnt)
    ? '-'
    : `${Number((totalSucCnt / totalCnt) * 100).toFixed(0)}%`;
}
function makeMergeQueue(that, other) {
  const queue = Object.keys(that).reduce((pre, current) => {
    if (current === 'queue') {
      return pre;
    }
    return {
      ...pre,
      [current]: Number(that?.[current]) + (Number(other?.[current]) || 0),
    };
  }, {});

  if (!isEmpty(queue)) {
    return {
      ...queue,
      ratio: makeCallRatio(queue),
    };
  }

  return queue;
}

export const onMessageEventQueue = (data) => {
  let callback;
  // 인입 전화번호(15331244_1 테스트 전화번호)
  switch (data.queue) {
    case '16601344_1':
      if (isProduction()) {
        callback = (state) => {
          state.catsQueue = {
            ...data,
            radio: makeCallRatio(data),
          };
          state.mergeQueue = makeMergeQueue(data, state.catsHomeQueue);
        };
      }
      break;
    case '16601244_1':
      if (isProduction()) {
        callback = (state) => {
          state.catsHomeQueue = {
            ...data,
            ratio: makeCallRatio(data),
          };
          state.mergeQueue = makeMergeQueue(data, state.catsQueue);
        };
      }
      break;
    case '15331244_1':
      if (isDevelopment() || isLocal()) {
        callback = (state) => {
          state.catsQueue = {
            ...data,
            ratio: makeCallRatio(data),
          };
          state.mergeQueue = makeMergeQueue(data, {});
        };
      }
      break;
    default:
      break;
  }
  if (!callback) return null;
  return [callback, false, '@@MONITOR :: [EVENT_QUEUE]'];
};
export const onMessageEventAgent = (data) => {
  return [
    (state) => {
      state.agents[data.agent] = data;
    },
    false,
    '@@MONITOR :: [EVENT_AGENT]',
  ];
};
export const onMessageEventMember = (data) => {
  return [
    (state) => {
      state.eventAgents[data.agent] = {
        ...data,
        readyTime: data.status === '2' && data.paused === '0' ? now() : '',
        loginTime:
          data?.status === '1'
            ? now()
            : state.eventAgents[data.agent]?.loginTime || '',
      };
    },
    false,
    '@@MONITOR :: [EVENT_MEMBER]',
  ];
};

export const onMessageEventCaller = (data) => {
  // 캐츠만 기록
  if (!CATS_QUEUE.includes(data.queue)) {
    return;
  }
  let callback;

  const timeStampedData = {
    ...data,
    seq: performance.now(),
    timestamp: now(),
  };
  switch (data.join) {
    case 0:
      callback = (state) => {
        state.abandonedCalls.push(timeStampedData);
      };
      break;
    case 1:
      callback = (state) => {
        state.joinedCalls.push(timeStampedData);
      };
      break;
    default:
      break;
  }
  if (!callback) return null;
  return [callback, false, '@@MONITOR :: [EVENT_CALLER]'];
};
