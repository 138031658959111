import dayjs from 'dayjs';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { isEmpty } from 'lodash';

import { immer } from '@store/index';

import { CATS_QUEUE } from '../data/constants';

const STORAGE_KEY_FOR_MONITOR_SOCKET = 'DANBI_CS_CENTER::MONITOR_SOCKET';

const initialState = {
  baseDate: dayjs().format('YYYY-MM-DD'),
  winkQueue: {},
  winkBooksQueue: {},
  mergeQueue: {},
  catsQueue: {},
  catsHomeQueue: {},
  agents: {},
  abandonedCalls: [],
  joinedCalls: [],
  eventAgents: {},
};

const state = (set) => ({
  ...initialState,
  setMonitorSocketState: (props) => {
    if (!props) return;
    if (props.some((value) => value === null)) return;
    set(...props);
  },
  clear: () =>
    set((draftState) => {
      Object.assign(draftState, initialState);
    }),
});

export const useMonitorSocketStore = create(
  persist(immer(state), {
    name: STORAGE_KEY_FOR_MONITOR_SOCKET,
  }),
);

export const catsEventAgentSelector = (monitorState) => {
  const eventAgents = {};
  Object.entries(monitorState.eventAgents).forEach(([key, value]) => {
    if (!isEmpty(value) && CATS_QUEUE.includes(value.queue)) {
      // if (!isEmpty(value)) {
      eventAgents[key] = { ...value, ...monitorState?.agents?.[key] };
    }
  });
  return eventAgents;
};
