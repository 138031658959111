import useAccountStore from '@store/useAccountStore';
import { useMemo } from 'react';
import dayjs from 'dayjs';
import CallCategory from './components/CallCategory';
import WaitingQueue from './components/WaitingQueue';
import { LIVE_CATEGORY } from './data/constants';
import {
  catsEventAgentSelector,
  useMonitorSocketStore,
} from './store/useMonitorSocketStore';

// CTI 상태 정보를 표시하는 패널
function CtiPanel() {
  const eventAgents = useMonitorSocketStore(catsEventAgentSelector);
  const agentNumber = useAccountStore((state) => state.agentNumber);
  const readyAgents = useMemo(() => {
    return Object.values(eventAgents)
      .filter((value) => value.readyTime)
      .sort(
        (itemA, itemB) =>
          dayjs(itemA.readyTime, 'HH:mm:ss').valueOf() -
          dayjs(itemB.readyTime, 'HH:mm:ss').valueOf(),
      )
      .slice(0, 4)
      .map((readyAgent, index) => ({
        ...readyAgent,
        key: `${readyAgent.agent}-${readyAgent.readyTime}-${index + 1}`,
      }));
  }, [eventAgents]);

  return (
    <div className="waiting-queue">
      {[...LIVE_CATEGORY].reverse().map(({ label, key }, index) => (
        <CallCategory
          key={`category_${index}`}
          label={label}
          field={key}
          index={index}
        />
      ))}
      <WaitingQueue readyAgents={readyAgents} agentNumber={agentNumber} />
    </div>
  );
}

export default CtiPanel;
