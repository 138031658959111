import { Descriptions } from 'antd';

import { toPhoneNumberFormat } from '@helper/formatHelper';

import useConsultationStore from '@store/useConsultationStore';

import MemberDetail from './MemberDetail';
import ConsultationButtonPanel from './ConsultationButtonPanel';

/**
 * 회원 상담 내역 상세 화면
 */
function DescriptionMember() {
  const currentMember = useConsultationStore((state) => state.currentMember);

  return (
    <div>
      <Descriptions
        bordered
        title={
          <>
            회원정보
            <ConsultationButtonPanel isMember />
          </>
        }
        column={2}
        className="mb-10"
        size="middle"
      >
        <Descriptions.Item labelStyle={{ width: 'fit' }} label="회원구분">
          <span className="text-secondary font-semibold">
            {`${
              currentMember?.memberTypeNm ||
              currentMember?.memberTypes?.[0]?.memberTypeNm
            }`}
          </span>
          <span>
            {`${
              currentMember?.courseNm
                ? ` / ${currentMember?.courseNm} ${currentMember?.gradeNm}`
                : ''
            }`}
          </span>
        </Descriptions.Item>
        <Descriptions.Item labelStyle={{ width: 'fit' }} label="회원번호">
          {currentMember?.memberId}
        </Descriptions.Item>
        <Descriptions.Item labelStyle={{ width: 'fit' }} label="전화번호">
          {toPhoneNumberFormat(currentMember?.mobile || '-')}
        </Descriptions.Item>
        <Descriptions.Item labelStyle={{ width: 'fit' }} label="이름">
          {currentMember?.memberName}
        </Descriptions.Item>
        <Descriptions.Item labelStyle={{ width: 'fit' }} label="학교">
          {currentMember?.school
            ? `${currentMember.school?.region} ${currentMember.school?.schoolNm}`
            : '-'}
        </Descriptions.Item>
      </Descriptions>

      <MemberDetail currentMember={currentMember} />
    </div>
  );
}

export default DescriptionMember;
