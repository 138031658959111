import { toPhoneNumberFormat } from '@helper/formatHelper';
import { callSocketActions } from './callSocketActions';

/**
 * 전화 걸기
 * @param {string} customerPhone - 고객 전화번호 (redirect 시 사용)
 * @param {string} customerId - 고객 id (redirect 시 사용)
 * @param {string} currentPath - 현재 경로 (location.pathname)
 * @param {function} navigate - 페이지 이동 함수
 * @param {function} onComplete - 전화 걸기 완료 후 실행할 함수
 * @returns
 */
export const handleOutboundCall = ({
  customerPhone,
  customerId,
  currentPath,
  navigate,
  onComplete,
}) => {
  if (!customerPhone) return;

  const targetPhonePath = `/consultation/${customerPhone}`;
  const targetIdPath = `/consultation/${customerId}`;

  // 이미 상담메모에 있으면 페이지 유지, 아닌 경우에만 상담메모 페이지로 이동
  if (currentPath !== targetPhonePath && currentPath !== targetIdPath) {
    navigate(targetPhonePath);
  }

  // 문서 및 수신거부 상태로 변경
  callSocketActions.qPause('10');
  callSocketActions.qPause('13');
  // 전화 걸기
  callSocketActions.outCall(toPhoneNumberFormat(customerPhone));

  if (onComplete) {
    onComplete();
  }
};
