import { useMemo } from 'react';
import { FloatButton } from 'antd';

import { useMonitorSocketStore } from '../store/useMonitorSocketStore';

const POSITION_RIGHT = 35;
const BUTTON_GAP = 85;

function CallCategory({ label, field, index }) {
  const queue = useMonitorSocketStore((state) => state.mergeQueue);

  const count = useMemo(() => {
    return queue?.[field] || '0';
  }, [queue, field]);

  return (
    <FloatButton
      shape="square"
      description={label}
      badge={{ count, showZero: true, style: { cursor: 'default' } }}
      className="w-[70px] h-[40px] top-[70px] z-[1000]"
      style={{
        right: POSITION_RIGHT + BUTTON_GAP * index,
        cursor: 'default',
        pointerEvents: 'none',
      }}
    />
  );
}

export default CallCategory;
