import { useEffect, useState } from 'react';
import { useUnmount } from 'ahooks';
import classNames from 'classnames';

import useConsultationDetail from '@hooks/consultation/useConsultationDetail';
import useConsultationStore from '@store/useConsultationStore';

import DescriptionMember from './components/DescriptionMember';
import DescriptionNonMember from './components/DescriptionNonMember';
import ConsultationDrawer from './components/ConsultationDrawer';

function ConsultationPageDetail() {
  const { members, currentMember, setCurrentMember, setMembers } =
    useConsultationStore((state) => state);
  const { handleCheckMembers } = useConsultationDetail();

  const [isDrawerOpen, setDrawerOpen] = useState(true);

  // 조회한 회원이 있는 경우 (1명 이상)
  useEffect(() => {
    if (Number(members?.total) > 0) {
      handleCheckMembers(members);
    }
  }, [handleCheckMembers, members]);

  // 페이지 이동 시 store 삭제
  useUnmount(() => {
    setMembers({});
    setCurrentMember({});
  });

  return (
    <>
      <ConsultationDrawer open={isDrawerOpen} handleOpen={setDrawerOpen} />
      <div className="flex flex-row w-full min-w-[1028px]">
        <div
          className={classNames(
            isDrawerOpen ? `w-[calc(100%-530px)]` : `w-[calc(100%-83px)]`,
          )}
        >
          {currentMember?.memberId ? (
            <DescriptionMember />
          ) : (
            <DescriptionNonMember />
          )}
        </div>
      </div>
    </>
  );
}

export default ConsultationPageDetail;
