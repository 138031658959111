import { Modal, Button, Table, Descriptions } from 'antd';
import { useOverlayContext } from '@context/OverlayContext';
import { useGetConsultation } from '@api/consultation';
import useCodeStore, { CODE_KEY } from '@store/useCodeStore';
import {
  DATE_FORMAT,
  toDateFormat,
  toPhoneNumberFormat,
} from '@helper/formatHelper';
import { CALL_TYPE } from '@constants/consultation';
import { useState } from 'react';
import { usePostConsultReservationsComplete } from '@api/reservation';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCallerContext } from '@context/CallerContext';
import { handleOutboundCall } from '@feature/cti/actions/outboundCallActions';
import ModalContainer from './base/ModalContainer';

/**
 * 상담 예약 > 전화 버튼 클릭 시 열리는 상담 내역 모달
 */
function ModalConsultation({ consultation }) {
  const [page, setPage] = useState(1);

  const { isOnCall } = useCallerContext();
  const { closeModal, openAlert } = useOverlayContext();
  const navigate = useNavigate();
  const { data: consultations } = useGetConsultation({
    page,
    size: 10,
    startDate: '2023-01-01',
    endDate: toDateFormat(new Date(), DATE_FORMAT.DATE),
    customerPhone: consultation?.customerContactNumber ?? '',
  });
  const location = useLocation();

  const handleCallSuccess = () => {
    handleOutboundCall({
      customerPhone: consultation.customerContactNumber,
      customerId: consultation.id,
      currentPath: location.pathname,
      navigate,
      onComplete: closeModal,
    });
  };

  const completeReservation =
    usePostConsultReservationsComplete(handleCallSuccess);

  const handleSetPage = (no) => {
    setPage(no);
  };

  const { [CODE_KEY.CONSULTATION_CATEGORY]: consultCategories } = useCodeStore(
    (state) => state.code,
  );

  const columns = [
    {
      title: '카테고리',
      dataIndex: 'category',
      render: (category) => consultCategories?.[category] || '-',
    },
    { title: '내용', dataIndex: 'content', width: '40%' },
    {
      title: '날짜',
      dataIndex: 'createdDate',
      render: (createdDate) => toDateFormat(createdDate),
    },
    {
      title: '수신/발신',
      dataIndex: 'callType',
      render: (callType) => (callType ? CALL_TYPE[callType]?.LABEL : '-'),
    },
  ];

  const handleOutCall = () => {
    if (isOnCall) return;
    if (!consultation?.customerContactNumber || !consultation?.id) {
      openAlert('전화번호 또는 회원 id가 없습니다.');

      return;
    }

    completeReservation(consultation.id);
  };

  return (
    <ModalContainer>
      <Modal
        open
        title="상담 내역"
        closable={false}
        footer={
          <div className="flex justify-end gap-2">
            <Button onClick={() => closeModal()}>취소</Button>
            <Button type="primary" onClick={handleOutCall} disabled={isOnCall}>
              전화걸기
            </Button>
          </div>
        }
        width="60%"
        bodyStyle={{
          maxHeight: 'calc(100vh - 300px)',
          overflowY: 'auto',
        }}
      >
        <Descriptions bordered className="mb-4">
          <Descriptions.Item label="회원명" span={1}>
            {consultation?.customerName ?? '-'}
          </Descriptions.Item>
          <Descriptions.Item label="회원구분" span={1}>
            {consultation?.customerId ? '회원' : '비회원'}
          </Descriptions.Item>
          <Descriptions.Item label="전화번호" span={1}>
            {toPhoneNumberFormat(consultation?.customerContactNumber) ?? '-'}
          </Descriptions.Item>
        </Descriptions>
        <Table
          rowKey="createdDate"
          dataSource={consultations?.list ?? []}
          columns={columns}
          pagination={{
            showSizeChanger: false,
            total: consultations?.total || 0,
            current: consultations?.pageNum || 1,
            onChange: (current) => handleSetPage(current),
          }}
        />
      </Modal>
    </ModalContainer>
  );
}

export default ModalConsultation;
