import dayjs from 'dayjs';

export const DATE_FORMAT = Object.freeze({
  TIMEZONE_DATE: 'YYYY-MM-DDTHH:mm:ss.SSSZZ',
  FULL_DATETIME: 'YYYY-MM-DD HH:mm:ss',
  DATE: 'YYYY-MM-DD',
  DATETIME: 'YYYY-MM-DD HH:mm',
  TIME: 'HH:mm',
  TIME_SEC: 'HH:mm:ss',
  DAY: 'ddd',
});

export const toPhoneNumberFormat = (phoneNumber = '') => {
  if (!phoneNumber) {
    return '-';
  }
  return phoneNumber?.includes('-')
    ? phoneNumber
    : phoneNumber?.replace(/(^0[0-9]{1,3})([0-9]{4})([0-9]{4})/, '$1-$2-$3');
};

export const toTelNumberFormat = (telNumber = '') => {
  if (!telNumber) {
    return '-';
  }

  const cleaned = telNumber.replace(/-/g, '');
  return cleaned.replace(/(\d{4})(\d{4})$/, '$1-$2');
};

export const toDateFormat = (date, format = 'YYYY-MM-DD HH:mm:ss') => {
  if (!date) {
    return '-';
  }
  return dayjs(date).format(format);
};

export const toMoneyFormat = (value) =>
  value ? `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원` : '';
