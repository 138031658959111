import { useMemo } from 'react';
import { Button, Col, Divider, Row, Table } from 'antd';

import { toPhoneNumberFormat } from '@helper/formatHelper';
import { useMonitorSocketStore } from '@feature/cti/store/useMonitorSocketStore';

function RealtimeStatusCall() {
  const { abandonedCalls, joinedCalls } = useMonitorSocketStore(
    (state) => state,
  );

  const callColumns = useMemo(
    () => [
      {
        title: '인입시간',
        dataIndex: 'timestamp',
      },
      {
        title: '발신번호',
        dataIndex: 'number',
        render: (number) => toPhoneNumberFormat(number),
      },
    ],
    [],
  );
  const abandonCallColumns = useMemo(
    () => [
      {
        title: '인입시간',
        dataIndex: 'timestamp',
      },
      {
        title: '발신번호',
        dataIndex: 'number',
        render: (number) => toPhoneNumberFormat(number),
      },
      {
        title: '전화걸기',
        dataIndex: 'number',
        render: () => <Button>전화걸기</Button>,
      },
    ],
    [],
  );

  return (
    <Row className="flex justify-around w-full mb-[10px]">
      <Divider>실시간 전화</Divider>
      <Col span={11}>
        <Table
          className="pt-3"
          rowKey="seq"
          columns={callColumns}
          dataSource={joinedCalls}
          caption={<strong>인입경로</strong>}
          pagination={{ pageSize: 5 }}
          bordered
        />
      </Col>
      <Col span={11}>
        <Table
          className="pt-3"
          rowKey="seq"
          columns={abandonCallColumns}
          dataSource={abandonedCalls}
          caption={<strong>포기호</strong>}
          pagination={{ pageSize: 5 }}
          bordered
        />
      </Col>
    </Row>
  );
}

export default RealtimeStatusCall;
