import { useMonitorSocketStore } from '@feature/cti/store/useMonitorSocketStore';
import { monitorSocket } from '@feature/cti/utils/Socket';
import { useCallback } from 'react';
import {
  onMessageEventAgent,
  onMessageEventCaller,
  onMessageEventMember,
  onMessageEventQueue,
} from '../messages/monitorMessage';

function useMonitorSocket() {
  const setMonitorSocketState = useMonitorSocketStore(
    (state) => state.setMonitorSocketState,
  );
  const initMonitorSocket = useCallback(() => {
    if (!monitorSocket)
      return Promise.reject(new Error('소켓에 연결되어 있지 않음'));

    monitorSocket
      // 3초 간격으로 event 지속적으로 발생
      .on('EVENT_QUEUE', (data) =>
        setMonitorSocketState(onMessageEventQueue(data)),
      )
      // 각 Device의 상태가 변경되면 발생
      .on('EVENT_AGENT', (data) =>
        setMonitorSocketState(onMessageEventAgent(data)),
      )
      // Queue에 접속된 member가 변경되면 발생
      .on('EVENT_MEMBER', (data) =>
        setMonitorSocketState(onMessageEventMember(data)),
      )
      // Queue에 event 발생시 발생 (Queue === 대표전화번호)
      // e.g. 1855-1244 에 전화가 들어옴 → 1855-1244 queue에 EVENT_CALLER 이벤트 발생
      .on('EVENT_CALLER', (data) =>
        setMonitorSocketState(onMessageEventCaller(data)),
      );
    return Promise.resolve();
  }, [setMonitorSocketState]);

  return {
    initMonitorSocket,
  };
}
export default useMonitorSocket;
