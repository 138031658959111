import { create } from 'zustand';
import { immer, logger } from '@store/index';

const initialState = {
  latestAgentCallEvent: '',
  call: {
    customerPhoneNumber: '',
    cid: '',
    agentNumber: '',
    eventType: '',
    ivr: '',
    startTime: '',
    endTime: '',
    file: '', // file이 백엔드 통신할땐 fileKey
  },
  device: {
    status: '',
  },
  queue: {
    status: 'notReadyToQueueConnect',
    connected: false,
  },
  error: { value: 0, label: '정상' },
};

const state = (set) => ({
  ...initialState,
  setCallSocketState: (props) => {
    if (!props) return;
    if (props.some((value) => value === null)) return;
    set(...props);
  },
  clearCall: () => {
    set((draft) => {
      draft.call = initialState.call;
    });
  },
});

export const useCallSocketStore = create(immer(logger(state)));
